import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import useSetQuery, { useMultiSetQuery } from 'customHooks/useQuery';
import { useStyles } from './EMEA_Common/EMEAKPIsWrapper';
import EMEAInnerChartCard from './EMEA_Common/EMEAInnerChartCard';

export default function EMEARejectionReasonsChart() {
    const classes = useStyles();
    const { t } = useTranslation();
    const setMultiParamsRR = useMultiSetQuery();
    // const theme = useTheme();
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
    const [params] = useSetQuery();

    const [innerWidthRR] = useState(() => window.innerWidth - window.innerWidth/10 * 1)
    const [unitRR, setUnit] = useState(() => localStorage.getItem("currency") || "USD");

    useEffect(() => {
        setUnit(localStorage.getItem("currency") || "USD");
    }, [params.currency]);

    const setDetailsRR = useCallback(({ key, data, type }) => {
        let payload = data.state;
        setMultiParamsRR({
            emeaFilters: JSON.stringify({
                [key]: type === "array" ? [payload] : payload
            }),
            isHyperlink: true
        });
    }, [params]);

    const additionalFiltersRR = {
        orderType: "ORDER_ENTRY"
    }

    const chartsTopRR = [
        {
            subtype: "SUBTYPE_EMEA_ORDER_REJECTION_REASON_CODE",
            chart: "trendline",
            yLabel: t('order_value_currency', { currency: unitRR }),
            colorBy: "xKey",
            colorPalette: "set9",
            trendline: false,
            showTotal: true,
            unitOfTotal: unitRR,
            defaultColor: "",
            legend: false,
            xKey: "stateDesc",
            displayKey: "stateDesc",
            tooltipKey: "rejectedPer",
            showXAxisLabel: !isMobile,
            maxWidth: 920,
            minWidth: 920,
            showBarLabel: false,
            tooltipOptions: {
                showTotal: false
            },
            keys: ["totalCount", "stateDesc", "rejectedPer"],
            keyLabelMaps: {
                stateDesc: t('stateDesc'),
                rejectedPer: t('rejectedPer'),
                totalCount: t('totalCount'),
            },
            setDetails: (data) => {
                setDetailsRR({
                    key: "itemReasonCodeList",
                    data,
                    type: 'array'
                })
            },
            tooltipExclusion: ["other than top 14"],
            isHyperlinkTooltip: true
        }
    ];

    return (
        <div data-testid="cutsAndRejectionskpiswrapper">
            
            {
                 
                        isMobile && [...chartsTopRR].map(d => {
                            return (
                                <Card className={classes.chartCard2}>
                                    <CardHeader title={<Typography variant="body1" component={"body1"}>{t("rejection_reasons")}</Typography>} />
                                    <EMEAInnerChartCard {...d} maxWidth={innerWidthRR} minWidth={innerWidthRR} boxShadow="none" excludedFilters={additionalFiltersRR} />
                                </Card>
                            )
                        })
            }
            {
                !isMobile && <>
                    <div>
                        <Grid container alignItems="center" justifyContent="center">
                            {chartsTopRR.map((d) => (
                                <Card className={classes.chartCard1}>
                                    <CardHeader title={<Typography variant="body1" component={"body1"}>{t("rejection_reasons")}</Typography>} />
                                    <EMEAInnerChartCard {...d} excludedFilters={additionalFiltersRR} />
                                </Card>
                                    
                            ))
                            }
                        </Grid>
                    </div>
                </>
            }
        </div>
    );
}

