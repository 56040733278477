import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeepCompareEffect } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import CardActions from '@material-ui/core/CardActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import RefreshOutlinedIcon from '@material-ui/icons/RefreshOutlined';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from "moment-timezone";
import { emeaSummaryService } from 'reduxLib/services/EmeaServices/emeaSummaryService';
import { boxShadow, chartsHeight as height } from 'helpers/styleHelpers';
import { useEMEAGlobalContext } from 'context/EMEAGlobalContext';
import LineChart from '../EMEA_Charts/LineChart';
import useSetQuery from 'customHooks/useQuery';
import EMEAInnerChartCard from './EMEAInnerChartCard';
import { formatLocaleNumber } from 'helpers/emeaHelpers';

const useStyles = makeStyles((theme) => ({
    title: {
        marginRight: "auto",
        paddingBottom: theme.spacing(2),
        fontSize: 18,
        textTransform: "none",
    },
    chartCard: {
        height: "auto",
        borderRadius: theme.spacing(3),
        padding: 0,
        boxShadow: boxShadow,
        width: `90vw`
    },
    viewmore: {
        cursor: "pointer",
        margin: theme.spacing(2),
        marginBottom: theme.spacing(.5),
        color: theme.palette.link?.primary,
        textTransform: "none"
    },
    padZero: {
        padding: 0,
        paddingBottom: theme.spacing(1)
    },
    total: {
        marginRight: theme.spacing(0.5),
        textTransform: "uppercase"
    },
    listTitle: {
        padding: 0,
        '&:before': {
            content: "'• ' ",
            display: "inline-block",
            textDecoration: "none",
            paddingRight: theme.spacing(1)
        },
    },
    select: {
        border: '1px solid black',
        borderRadius: 5,
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        marginLeft: theme.spacing(3),
    },
    menuPaper: {
        maxHeight: 300
    }
}));


const EMEAChartCardLarge = ({
    subtype, title, chart,
    trendline, excludedFilters = {},
    innerChartProps, colorParams, textParams, 
    currency, unit, ...rest
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const [controller] = useState(() => new AbortController());
    const { loading, data } = useSelector(({ emeaSummaries }) => emeaSummaries?.[subtype] || {});
    const [expanded, setExpanded] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [{ loadView }] = useSetQuery();
    const [total, setTotal] = useState(() => data?.reduce((a, b) => a + b.totalCount, 0));

    const {
        locations,
        customers = [],
        sofcs = [],
        BU,
        documentDate
    } = useEMEAGlobalContext();

    const [singleDocumentDate, setSingleDocDate] = useState(() => documentDate?.endTime);

    const refreshLineChart = () => {

        if (loadView) return null;

        const payload = {
            region: "EMEA",
            subtype,
            businessUnits: BU,
            sites: locations,
            documentDate,
            customerOrSalesOffice: [...customers, ...sofcs],
            currency: localStorage.getItem("currency") || "USD",
            orderType: "ORDER_ENTRY",
            ...excludedFilters,
            fillDates: true
        }
        dispatch(emeaSummaryService({ payload, controller }));
    }

    useDeepCompareEffect(() => {
        refreshLineChart();
    }, [subtype, locations, sofcs, documentDate, customers, BU, localStorage.getItem("currency"), refresh]);

    useEffect(() => {
        setSingleDocDate(documentDate?.endTime);
    }, [documentDate]);

    useDeepCompareEffect(() => {
        setTotal(data?.reduce((a, b) => a + b.totalCount, 0));
    }, [data]);

    useEffect(() => {
        return () => {
            controller.abort();
        }
    }, []);

    const handleExpandClick = () => {
        setExpanded((e) => !e)
    }

    const handleDateChange = (e) => {
        setSingleDocDate(moment(e).format("YYYY-MM-DD"));
    }

    const chartProps = {
        ...rest,
        data: data || [],
        loading,
        height: height - 100,
        xKey: "stateDesc",
        keys: ["totalCount"],
        yKey: "totalCount",
        themeType: "light",
        trendline,
        subtype,
        dx: "-11%"
    }

    return (
        <div data-testid="chartcard-large">
            <CardHeader
                className={classes.padZero}
                action={
                    <>
                        {!loading && <span className={classes.total}>{t("total")}: {currency} {formatLocaleNumber(total) || 0} {unit}</span>}
                        <IconButton data-testid="refreshbtn" size='small' onClick={() => setRefresh(!refresh)}>
                            <RefreshOutlinedIcon fontSize='small' />
                        </IconButton>
                    </>
                }
                title={<ListItem className={classes.listTitle} sx={{ display: 'list-item' }}>{t(title)}</ListItem>}
            />
            <Card className={classes.chartCard} data-testid="chartscard">
                <CardHeader />
                <LineChart {...chartProps} />
                <CardActions disableSpacing>
                    <Button
                        className={classes.viewmore}
                        onClick={handleExpandClick}
                        disableRipple
                        data-testid="viewmore-btn"
                        size='small'
                        endIcon={expanded ? <ExpandLess /> : <ExpandMoreIcon />}
                    >
                        {
                            expanded ? t("view_less") : t(textParams?.viewMore)
                        }
                    </Button>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                minDate={new Date(moment(documentDate?.startTime, "YYYY-MM-DD").toDate())}
                                maxDate={new Date(moment(documentDate?.endTime, "YYYY-MM-DD").toDate())}
                                id="date-picker-emea"
                                data-test-id="date-picker-emea"
                                autoOk
                                value={new Date(moment(singleDocumentDate, "YYYY-MM-DD").toDate())}
                                onChange={handleDateChange}
                                className={classes.select}
                                InputProps={{
                                    disableUnderline: true
                                }}
                            />
                        </MuiPickersUtilsProvider>
                        <EMEAInnerChartCard
                            {...innerChartProps}
                            {...colorParams}
                            refresh={refresh}
                            chart="trendline"
                            trendline={false}
                            maxWidth={"auto"}
                            showTotal
                            singleDocumentDate={singleDocumentDate}
                            additionalFilters={{
                                documentDate: undefined,
                                orderType: "ORDER_ENTRY"
                            }}
                            dx={"-11%"}
                        />
                    </>
                </Collapse>
            </Card>
        </div>
    )
}

export default EMEAChartCardLarge;